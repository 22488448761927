import React, { useState } from 'react'
import styles from './Accordion.module.scss'
import TopArrowMd from '@/asset/img/redesign24/top-arrow-med.svg'
import classNames from 'classnames'
import { node, string, bool, func } from 'prop-types'
import { useSpring, animated } from 'react-spring/web.cjs'
import useMeasure from '@/component/Primitive/Expander/useMeasure'
import { shape } from 'prop-types'
import { object } from 'prop-types'

export const Accordion = ({ title, children, visibleDefault, onChange,options }) => {
  const [toggleAccordion, setToggleAccordion] = useState(visibleDefault)
  const [bind, { height }] = useMeasure()
  const eleHeight = useSpring({
    height: toggleAccordion ? height + 32 : 0
  })
  return (
    <>
      <button
        onClick={() => {
          setToggleAccordion(!toggleAccordion)

          if (onChange) {
            onChange(!toggleAccordion)
          }
        }}
        className={classNames(
          styles.AccordionWrap,
          visibleDefault && styles.AccordionWrapNoBottomBorder,
          toggleAccordion && styles.ActiveAccordionWrap
        )}
      >
        {visibleDefault ? (
          <div className={styles.AccordionHeading}>
            <span className={classNames(styles.AccordionHeadingTitle,options?.title)}>{title}</span>
          </div>
        ) : (
          <div className={styles.AccordionHeading}>
            <span className={classNames(styles.AccordionHeadingTitle,options?.title)}>{title}</span>
            <img
              className={classNames(
                styles.ArrowIcon,
                toggleAccordion && styles.ActiveArrowIcon
              )}
              src={TopArrowMd}
              alt=""
            />
          </div>
        )}
      </button>
      {children && (
        <animated.div
          style={eleHeight}
          className={classNames(
            styles.AccordionContent,
            toggleAccordion && styles.ActiveAccordionContent
          )}
        >
          <div {...bind}>{children}</div>
        </animated.div>
      )}
    </>
  )
}
Accordion.propTypes = {
  title: string,
  children: node,
  visibleDefault: bool,
  onChange: func,
  options: shape({
    title: object
  })
}
