import React from 'react'
import PropTypes from 'prop-types'
import HomeHero from '@/component/Common/HomeHero'

const HomeHeroWidget = ({ items }) => {

  const getImages = (item) => {
    const desktopImage =
      item &&
      item.images &&
      item.images.hero_16x9_928 &&
      item.images.hero_16x9_928[0]
    const mobileImage =
      item &&
      item.images &&
      item.images.hero_16x9_550 &&
      item.images.hero_16x9_550[0]
    const lgDesktopImage =
      item &&
      item.images &&
      item.images.hero_1440x400_1440 &&
      item.images.hero_1440x400_1440[0]
    const xlDesktopImage = item &&
      item.images &&
      item.images.hero_2545x783_2545 &&
      item.images.hero_2545x783_2545[0]

    const articleImage =item&&  item.images &&
      item.images.hero_1527x784_1527 &&
      item.images.hero_1527x784_1527[0]

    return {
      desktopImage,
      mobileImage,
      lgDesktopImage,
      xlDesktopImage,
      articleImage
    }
  }

  const mapItem = (item) => {

    if (item.type === 'announcement') {
      return {
        type: item.type,
        title: item.title,
        subHeading: item.subHeading,
        rating: '',
        displayType: 'fullImage',
        url: item.link,
        image: getImages(item)
      }
    }
    return {
      type: item.type,
      title: item.titleOverride || item.article.shortHeadline,
      rating: item.article.contentRating || 'u',
      displayType: 'halfImage',
      url: item.article.fullUrlPath,
      image: getImages(item.article),
      sell: item.article.sell || ''
    }
  }

  // Filter out items with no images
  const heroItems = items
    .map(mapItem)
    .filter((item) => item.image.desktopImage || item.image.mobileImage)

  return <HomeHero items={heroItems} />
}

HomeHeroWidget.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      title: PropTypes.string,
      link: PropTypes.string,
      images: PropTypes.shape({
        hero_16x9_928: PropTypes.arrayOf(PropTypes.string)
      }),
      titleOverride: PropTypes.string,
      sell: PropTypes.string
    })
  )
}

export default HomeHeroWidget
