import React from 'react'
import PropTypes from 'prop-types'
import styles from './HalfImageItem.module.scss'
import classNames from 'classnames'

const HalfImageItem = ({ url, title, image, rating, sell }) => {
  const sharpEdge = rating === 'U' || rating === 'PG'
  //image.articleImage.url.includes('Error') --> article image crop size is not available
  const backgroundImage =image.articleImage.url.includes('Error') ? image.desktopImage : image.articleImage

  const renderTitle = () => {
    const displayTitle = title
    if (!displayTitle) {
      return null
    }

    // Check if we have HTML tags. If so then we need to render it as HTML
    if (displayTitle.includes('<p>') && displayTitle.includes('</p>')) {
      return (
        <div
          className={styles.Title}
          dangerouslySetInnerHTML={{ __html: displayTitle }}
        />
      )
    }

    return <h2 className={styles.Title}>{displayTitle}</h2>
  }

  return (
    <a href={url} className={styles.ContainerWrap}>
      <div
        className={classNames(styles.Container)}
        style={{ backgroundImage: `url(${image.desktopImage.url})` }}
      >
        <span role="img" aria-label={image.desktopImage.alt || title} />
        <div className={styles.DesktopImage}>
          <div />
          <div
            className={styles.HalfWidthImage}
            style={{ backgroundImage: `url(${backgroundImage?.url})` }}
          />
        </div>
        <div
          className={classNames(
            styles.RatingBkgWrap,
            styles[`RatingBkg-${rating.toLowerCase()}`],
            sharpEdge ? styles.SharpEdge : styles.CurveEdge
          )}
        />
        <div className={styles.ContentWrap}>
          <div className={styles.ContentDesktop}>
            {renderTitle()}
            {sell && <p className={styles.Sell}>{sell}</p>}
            <p className={styles.ReadMore}>Read more</p>
          </div>
        </div>
      </div>
      <div
        className={classNames(
          styles.ContentMobile,
          styles[`RatingBkg-${rating.toLowerCase()}`]
        )}
      >
        {renderTitle()}
        <p className={styles.ReadMore}>Read more</p>
      </div>
    </a>
  )
}
HalfImageItem.propTypes = {
  url: PropTypes.string,
  title: PropTypes.string,
  sell: PropTypes.string,
  image: PropTypes.shape({
    desktopImage: PropTypes.shape({
      url: PropTypes.string,
      alt: PropTypes.string
    }),
    lgDesktopImage: PropTypes.shape({
      url: PropTypes.string,
      alt: PropTypes.string
    })
  }),
  rating: PropTypes.string
}
export default HalfImageItem
